import React, { useCallback } from 'react';

import {
  useAuthWithBiometryV5Process,
  AuthWithBiometryV5ProcessProvider,
} from '@liscio/common';
import { Box, styled } from '@liscio/ui';
import { defaultErrorParser } from '@liscio/utils';

import {
  Logo,
  Copyright,
  SignInForm,
  LoginFormInterface,
  LoadingPlaceholder,
  MfaSetupDesktopOnlyInfo,
} from './components';
import { ErrorDialog } from 'components/ErrorDialog';
import { config } from 'config';
import {
  CpaLoginV5Selector,
  MfaCodeForm,
} from 'modules/authenticate/components';
import { useSetLoggedInUserSession } from 'modules/authenticate/hooks';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.common.white,
  paddingTop: '48px',
  paddingBottom: '16px',
}));

export const LoginV5View = () => {
  const setLoggedUserSession = useSetLoggedInUserSession();

  const authV5ProcessState = useAuthWithBiometryV5Process({
    onProcessFinished: async (loggedInUser) => {
      await setLoggedUserSession(loggedInUser);
    },
    webAppUrl: config.webApp.url,
  });

  const {
    processState,
    signInCpas,
    signIn,
    resetProcess,
    selectCpa,
    validateMfaCode,
    isLoading,
    error,
    getSelectedCpa,
  } = authV5ProcessState;

  const handleSignInForm = useCallback(
    (signInFormData: LoginFormInterface) => {
      return signIn({
        email: signInFormData.email,
        password: signInFormData.password,
        rememberMe: Boolean(signInFormData.enableRememberMe),
        enableBiometrics: Boolean(signInFormData.enableBiometrics),
      });
    },
    [signIn]
  );

  if (processState === 'cpaSelector') {
    return (
      <CpaLoginV5Selector
        cpas={signInCpas}
        onCancel={resetProcess}
        onCpaSelected={(selectedCpa) => {
          selectCpa(selectedCpa.cpa_uuid!);
        }}
      />
    );
  }

  if (processState === 'mfaSetup') {
    return (
      <MfaSetupDesktopOnlyInfo
        resetProcess={resetProcess}
        getSelectedCpa={getSelectedCpa}
      />
    );
  }

  return (
    <>
      <Logo />
      <Wrapper>
        <AuthWithBiometryV5ProcessProvider
          authWithBiometryV5ProcessState={authV5ProcessState}
        >
          {processState === 'loginForm' && (
            <SignInForm isLoading={isLoading} onSubmit={handleSignInForm} />
          )}
          {processState === 'mfaCode' && (
            <MfaCodeForm
              backToLogin={resetProcess}
              isLoading={isLoading}
              onSubmit={({ mfaCode }) => validateMfaCode(mfaCode)}
            />
          )}
          {processState === 'cpaSelected' && <LoadingPlaceholder />}
        </AuthWithBiometryV5ProcessProvider>
        <ErrorDialog
          title="Sign In Error"
          error={error}
          parseError={defaultErrorParser}
        />
      </Wrapper>
      <Copyright />
    </>
  );
};

export default LoginV5View;
