import { useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
  MagicLinkLoginProcessState,
  useMagicLinkAuthV5Process,
} from '@liscio/common';
import { GlobalLoader, Box, styled } from '@liscio/ui';

import { MfaSetupDesktopOnlyInfo } from '../LoginV5View/components';
import { ErrorDialog } from 'components/ErrorDialog';
import { MfaCodeForm } from 'modules/authenticate/components';
import { CpaLoginV5Selector } from 'modules/authenticate/components/CpaLoginV5Selector/CpaLoginV5Selector';
import { useSetLoggedInUserSession } from 'modules/authenticate/hooks';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.common.white,
  paddingTop: '48px',
  paddingBottom: '16px',
}));

export const MagicLinkLoginV5View = () => {
  const setLoggedUserSession = useSetLoggedInUserSession();

  const navigate = useNavigate();
  const backToLogin = useCallback(
    () => navigate('/authenticate/login', { replace: true }),
    [navigate]
  );
  const { link } = useParams<{ link: string }>();

  const {
    processState,
    selectCpa,
    signInCpas,
    error,
    onSubmit,
    getSelectedCpa,
  } = useMagicLinkAuthV5Process({
    token: link || '',
    onProcessFinished: async (loggedInUser) => {
      await setLoggedUserSession(loggedInUser);
    },
  });

  // Cpa selection
  if (processState === 'cpaSelector') {
    return (
      <>
        <CpaLoginV5Selector
          onCancel={backToLogin}
          cpas={signInCpas}
          onCpaSelected={(selectedCpa) => {
            selectCpa(selectedCpa.cpa_uuid!);
          }}
        />
        <ErrorDialog title="Sign In Error" error={error} />
      </>
    );
  }

  if (processState === 'mfaSetup') {
    return (
      <MfaSetupDesktopOnlyInfo
        resetProcess={backToLogin}
        getSelectedCpa={getSelectedCpa}
      />
    );
  }

  if (
    (processState === 'cpaSelected' ||
      processState === 'initialVerification') &&
    !error
  ) {
    return <GlobalLoader open />;
  }

  return (
    <>
      <Wrapper>
        {processState === 'mfaCode' && (
          <MfaCodeForm backToLogin={backToLogin} onSubmit={onSubmit} />
        )}
      </Wrapper>
      <ErrorDialog
        error={error}
        onClose={
          (['mfaCode', 'cpaSelector'] as MagicLinkLoginProcessState[]).includes(
            processState
          )
            ? () => {}
            : backToLogin
        }
      />
    </>
  );
};
