import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { AuthErrorMessageTypes } from '@liscio/api';
import { useApiClient } from '@liscio/common';
import { containsErrorOfType } from '@liscio/utils';

export const AxiosInterceptors = () => {
  const { client } = useApiClient();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const responseInterceptor = client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response.status === 403 &&
          containsErrorOfType(error, AuthErrorMessageTypes.firm_requires_mfa) &&
          location.pathname !== '/enforced-mfa-configuration'
        ) {
          navigate('/enforced-mfa-configuration', {
            state: { goBackUrl: location.pathname },
          });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      client.interceptors.response.eject(responseInterceptor);
    };
  }, [
    client.interceptors,
    client.interceptors.response,
    location.pathname,
    navigate,
  ]);

  return null;
};
