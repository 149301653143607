import { useCallback, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AuthorizedCpaUser,
  usePatchApiV5AuthMfaSetups,
  usePostApiV5AuthMfaSetups,
} from '@liscio/api';
import { MfaSetupComplete, MfaSetupProcess } from '@liscio/common';
import { useViewportSize } from '@liscio/ui';

import { useSetLoggedInUserSession } from 'modules/authenticate/hooks';
import { MfaSetupDesktopOnlyInfo } from 'modules/authenticate/views/LoginV5View/components';
import { useCpa } from 'stores/cpa';

export const EnforcedMfaConfiguration = () => {
  const { isMobile } = useViewportSize();
  const { cpa } = useCpa();
  const navigate = useNavigate();
  const setLoggedUserSession = useSetLoggedInUserSession();

  const location = useLocation();
  const stateGoBack = location.state?.goBackUrl;
  const goBackUrl = stateGoBack || '/dashboard';

  const [configurationState, setConfigurationState] = useState<
    'mfaSetup' | 'mfaSetupComplete'
  >('mfaSetup');

  const getSelectedCpa = useCallback(
    () => ({
      cpa_name: cpa?.name,
    }),
    [cpa?.name]
  );

  /**
   * Load MFA setup configuration
   */
  const { mutateAsync: loadMfaSetupConfiguration } =
    usePostApiV5AuthMfaSetups();
  const { data: mfaSetupConfiguration, isLoading: isMfaSetupLoading } =
    useQuery({
      queryKey: ['mfaSetupConfiguration'],
      queryFn: async () => loadMfaSetupConfiguration(),
      enabled: !isMobile,
    });

  /**
   * Setup MFA
   */
  const { mutateAsync: setMfa, isLoading: isSettingUpMfa } =
    usePatchApiV5AuthMfaSetups({
      mutation: {
        onSuccess: (newLoggedUser) => {
          // refresh auth token
          setLoggedUserSession(newLoggedUser as AuthorizedCpaUser); // we have a small auth_token mismatch
          setConfigurationState('mfaSetupComplete');
        },
      },
    });

  if (isMobile) {
    return (
      <MfaSetupDesktopOnlyInfo
        getSelectedCpa={getSelectedCpa}
        resetProcess={() => navigate('/authenticate/logout')}
      />
    );
  }

  return (
    <>
      {configurationState === 'mfaSetup' && (
        <MfaSetupProcess
          isLoading={isMfaSetupLoading || isSettingUpMfa}
          qrCodeSrc={mfaSetupConfiguration?.qr_code}
          otpSecret={mfaSetupConfiguration?.unconfirmed_otp_secret}
          onSubmit={({ mfaCode }) =>
            setMfa({ data: { two_factor_code: mfaCode } })
          }
          getSelectedCpa={getSelectedCpa}
        />
      )}
      {configurationState === 'mfaSetupComplete' && (
        <MfaSetupComplete onDelayedSuccess={() => navigate(goBackUrl)} />
      )}
    </>
  );
};
