/*
 *  20240301 - Production License Web PI for Liscio Inc
 *  (*.lisciostaging.com|*.liscio.me|localhost)
 *  valid until November 15th, 2024.txt
 */
export const VITE_SCANBOT_KEY_WEB =
  "cO+MlstmX31ddlI/XrC5oVixe2ggqq" +
  "4gZJJpSqXqBIVN4xBbvoLGzKd7IcI4" +
  "Dw4+Gv6zwAqZrYDrvZtFyzcpBAUgX1" +
  "eO+63M734znLmf+XpOyZguoeTpaJQh" +
  "tS9hci+0cCO/4v/g0xW3nQwNqDE0Xj" +
  "PZP1u0N/6bJAn0uzwsjGzVEl7g3GI5" +
  "q4LOevRutCNmir7aVIJSB7PWtlI3EM" +
  "32BT78xjSJ3vVVrk7G++I23J2A15ji" +
  "twu5Xgz5clp02KKcBsWZXkZpUm4Gd0" +
  "xbWZcl5YlXWfkuScATayDjPMTc6HUK" +
  "5q/I0fKg6o+QQ27jBF6pwXNI1kNGOK" +
  "ZuzJD3FXmWbw==\nU2NhbmJvdFNESw" +
  "oqLmxpc2Npb3N0YWdpbmcuY29tfGxv" +
  "Y2FsaG9zdDozMDAwfCoubGlzY2lvLm" +
  "1lfGxvY2FsaG9zdAoxNzY4NTIxNTk5" +
  "CjU5MAo4\n";
