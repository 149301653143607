import { Capacitor } from '@capacitor/core';

const app = 'firm';
const environment =
  import.meta.env.VITE_ENVIRONMENT || import.meta.env.MODE || 'local';
const platform = Capacitor.getPlatform();

/**
 * Type-safe application config
 */
export const config = {
  app,
  environment,
  platform,
  appIdentifier: `${app}-${environment}-${platform}`,
  version: __APP_VERSION__ || '-',
  api: {
    url: import.meta.env.VITE_API_URL || 'https://api.lisciostaging.com',
  },
  auth: {
    // placeholder
  },
  webApp: {
    url: import.meta.env.VITE_WEB_APP_URL || 'https://m.lisciostaging.com',
  },
  legacyRedirect: {
    url: import.meta.env.VITE_FIRM_REDIRECT_URL,
  },
  launchDarkly: {
    clientId: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID || '',
    isEnabled: Boolean(import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID),
  },
  psPdfKit: {
    licenseKey: import.meta.env.VITE_PSPDFKIT_LICENSE_KEY,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN || '',
    isEnabled: Boolean(import.meta.env.VITE_SENTRY_DSN),
  },
  mixpanel: {
    projectToken: import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN,
  },
  userpilot: {
    appToken: import.meta.env.VITE_USERPILOT_KEY || '',
    isEnabled:
      Boolean(import.meta.env.VITE_USERPILOT_KEY) &&
      (!Boolean(import.meta.env.VITE_TEST) ||
        import.meta.env.VITE_TEST === 'false'),
  },
  oauth: {
    google: {
      url:
        import.meta.env.VITE_GOOGLE_OAUTH_URL ||
        'https://accounts.google.com/o/oauth2/v2/auth',
      clientId: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID || '',
      redirectUri: import.meta.env.VITE_GOOGLE_OAUTH_REDIRECT_URI || '',
      scope:
        import.meta.env.VITE_GOOGLE_OAUTH_SCOPE ||
        'https://www.googleapis.com/auth/userinfo.email',
    },
    outlook365: {
      url:
        import.meta.env.VITE_OUTLOOK_OAUTH_URL ||
        'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
      clientId: import.meta.env.VITE_OUTLOOK_OAUTH_CLIENT_ID || '',
      redirectUri: import.meta.env.VITE_OUTLOOK_OAUTH_REDIRECT_URI || '',
      scope:
        import.meta.env.VITE_OUTLOOK_OAUTH_SCOPE ||
        'openid profile email People.Read User.read',
    },
  },
  /**
   * Dev mode and debug config.
   * Not to be used in production envs!
   */
  dev: {
    /**
     * Default CPA custom_liscio_url to resolve in preview environments
     */
    defaultSiteCpa: import.meta.env.VITE_DEV_DEFAULT_SITE_CPA || '',
    /**
     * Disable native live updates for local native app development
     */
    disableNativeLiveUpdates: import.meta.env
      .VITE_DEV_DISABLE_NATIVE_LIVE_UPDATES,
  },
};
