import { ThemeExtension } from '@liscio/ui';

export const typography: ThemeExtension = (theme) => ({
  // this is a temporary(?) solution so we dont break the client app changing liscio/ui theme
  // typography overrides are mainly for keeping the breakpoints from changing font size
  // adding the whole typography object here to make sure liscio/ui theme changes dont break this firm theme
  typography: {
    h1: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      color: theme.palette.common.neutrals[800],
      fontWeight: 600,
      lineHeight: '110%',
      fontSize: '2.813rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2.813rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.813rem',
      },
      padding: '14px 24px',
    },
    h2: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '1.83rem',
      fontWeight: 500,
      lineHeight: '130%',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.83rem',
      },
    },
    h3: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '1.688rem',
      fontWeight: 400,
      lineHeight: '130%',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.688rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.688rem',
      },
    },
    h4: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '150%',
    },
    h5: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '150%',
    },
    h6: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '.938rem',
      fontWeight: 700,
      lineHeight: '110%',
    },
    subtitle1: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '1.188',
      fontWeight: 500,
      lineHeight: '150%',
    },
    subtitle2: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '150%',
    },
    body1: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '.938rem',
      fontWeight: 400,
      lineHeight: '160%',
      [theme.breakpoints.up('md')]: {
        fontSize: '.938rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '.938rem',
      },
    },
    body2: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '.938rem',
      fontWeight: 700,
      lineHeight: '160%',
      [theme.breakpoints.up('md')]: {
        fontSize: '.938rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '.938rem',
      },
    },
    label: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '160%',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.313rem',
        lineHeight: '120%',
      },
    },
    caption: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '0.8rem',
      fontWeight: 500,
      lineHeight: '150%',
    },
    overline: {
      fontFamily: "'Source Sans Pro', Sans-serif",
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '150%',
    },
  },
});
