import { styled, Box } from '@liscio/ui';

export const FooterWrapper = styled(Box)(({ theme }) => ({
  borderTop: `solid 1px ${theme.palette.common.blueGray[200]}`,
  padding: '16px 8px 32px 8px',
  textAlign: 'center',
}));

export const LightFooterWrapper = styled(Box)(() => ({
  padding: '16px 8px 32px 8px',
  textAlign: 'center',
}));

export default FooterWrapper;
